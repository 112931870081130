<template>
    <div class="main-footer">
        <router-link :to="item.link" class="main-link" v-for="item in routes[currentRoute]" :key="item">{{ item.title }}</router-link>
    </div>
</template>

<script>
    export default {
        name: 'FooterComponent',
        props: { 
            currentRoute: String
        },

        data() {
            return {
                routes: {
                    homePage: [
                        {  
                            link: '/case-studies',
                            title: 'SOLUTION'
                        }   
                    ],
                    agenda: [
                        {  
                            link: '/pitch-deck',
                            title: 'HOME'
                        },
                        {  
                            link: '/current-market',
                            title: 'MARKET'
                        },
                    ],
                    currentMarket: [
                        {  
                            link: '/agenda',
                            title: 'AGENDA'
                        },
                        {  
                            link: '/case-studies',
                            title: 'SOLUTION'
                        },
                    ],
                    clientsPage: [
                        // {  
                        //     link: '/benefits',
                        //     title: 'BENEFITS'
                        // },
                        // {  
                        //     link: '/next-steps',
                        //     title: 'NEXT STEPS'
                        // },
                    ],
                    nextSteps: [
                        // {  
                        //     link: '/clients',
                        //     title: 'CLIENTS'
                        // },
                        {  
                            link: '/pitch-deck',
                            title: 'HOME'
                        },
                    ],
                    benefitsPage: [
                        {  
                            link: '/case-studies',
                            title: 'SOLUTION'
                        },
                        // {  
                        //     link: '/clients',
                        //     title: 'CLIENTS'
                        // },
                    ],
                    caseStudiesPage: [
                        {  
                            link: '/pitch-deck',
                            title: 'HOME'
                        },
                        // {  
                        //     link: '/benefits',
                        //     title: 'BENEFITS'
                        // },
                    ],
                    qooPackages: [
                        {  
                            link: '/case-studies',
                            title: 'SOLUTION'
                        },
                        // {  
                        //     link: '/benefits',
                        //     title: 'BENEFITS'
                        // },
                    ],
                    animationsPage: [],
                    qooboxAndQootouch: [],
                    qdsAndTours: [],
                    virtualProduction: []
                }
            }
        },
        
    }
</script>

<style lang="scss">
    .main-footer{
        position: absolute;
        width: max-content;
        bottom: 0;
        padding: 0 20px;
        text-align: right;
        right: 0;
        .main-link{
            font: normal normal normal 17px/60px Neuzeit Grotesk;
            letter-spacing: 4.32px;
            color: #FFFFFF;
            text-transform: uppercase;
            text-decoration: underline;
            opacity: 1;
            margin-left: 16px;
        }
    }
</style>