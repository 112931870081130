<template>
  <div class="qoo-main">
    
    <div class="w-full h-full">
      <router-view></router-view>
    </div>
      
    <FooterComponent :currentRoute="currentRouteName"/>
    <OverlayLoader v-show="isLoading"/>
  </div>
</template>

<script>

import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
import FooterComponent from "@/components/base/Footer.vue";

export default {
  name: 'App',
  components: { OverlayLoader , FooterComponent },

  computed: {
    isLoading: {
      get(){
        return this.$store.state.isLoading;
      }
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  
}

</script>